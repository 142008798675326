import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';

import { Menu } from '../../components/Menu';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import api from '../../config/configAPi';

export const AddUser = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const valueInput = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const addUser = async (e) => {
    e.preventDefault();

    //console.log(user);
    if (!(await validate())) return;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    await api
      .post('/user', user, headers)
      .then((response) => {
        //console.log(response.data.mensagem);
        setStatus({
          type: 'success',
          mensagem: response.data.mensagem,
        });
      })
      .catch((err) => {
        if (err.response) {
          //console.log(err.response.data.mensagem);
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          //console.log('Tente novamente');
          setStatus({
            type: 'error',
            mensagem: 'Erro:Tente novamente',
          });
        }
      });
  };

  const mensagemAdd = {
    type: status.type,
    mensagem: status.mensagem,
  };

  // function validate(){
  //     if(!user.name)return setStatus({
  //         type:'error',
  //         mensagem:'Digite o nome do usuário'
  //     });
  //     if(!user.email)return setStatus({
  //         type:'error',
  //         mensagem:'Digite um email válido!'
  //     });
  //     if(!user.password)return setStatus({
  //         type:'error',
  //         mensagem:'Digite a senha!'
  //     });
  //     if(user.password.length < 6)return setStatus({
  //         type:'error',
  //         mensagem:'A senha deve conter no mínimo 6 caracteres!'
  //     });

  //     return true;
  // }

  async function validate() {
    let schema = yup.object().shape({
      password: yup
        .string('Erro: Digite a senha')
        .required('Erro: Digite a senha')
        .min(6, 'Erro: A senha deve ter no minimo 6 caracteres'),
      email: yup
        .string('Erro: Digite o email')
        .required('Erro: Digite o email'),
      name: yup.string('Erro: Digite o nome').required('Erro: Digite o nome'),
    });
    try {
      await schema.validate({
        name: user.name,
        email: user.email,
        password: user.password,
      });
      return true;
    } catch (err) {
      setStatus({
        type: 'error',
        mensagem: err.errors,
      });
      return false;
    }
  }

  return (
    <>
      <Menu />
      <Container className="boxListar">
        <h2>Cadastro de Usuário</h2>
        <br />
        {status.type === 'success' ? (
          <Navigate to="/users" state={mensagemAdd} />
        ) : (
          ''
        )}
        {status.type === 'error' ? (
          <p style={{ color: 'red' }}>{status.mensagem}!</p>
        ) : (
          ''
        )}

        <Form onSubmit={addUser}>
          <Form.Group className="mb-3">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Digite seu nome"
              onChange={valueInput}
            ></Form.Control>
            <br />

            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="digite o email do usuario"
              onChange={valueInput}
            ></Form.Control>
            <br />

            <Form.Label>Senha</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Digite a senha"
              autoComplete="on"
              onChange={valueInput}
            ></Form.Control>
            <br />
          </Form.Group>
          <div className="gap-3">
            <Button type="submit">
              <i className="fa-solid fa-floppy-disk-circle-arrow-right"></i>{' '}
              Cadastrar
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};
